import React from 'react';

const ScreenGuide = () => {
  const styles = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1,
    border: '1px solid red',
    width: '1920px',
    height: '1080px',
    PointerEvent: 'none',
  };

  return <div style={styles}></div>;
};

export default ScreenGuide;