import React, {Component} from "react";
import Screen1 from "./pages/Screens/Screen1";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Screen2 from "./pages/Screens/Screen2";
import HomePage from "./pages/HomePage";
import Screen3 from "./pages/Screens/Screen3";
import Screen4 from "./pages/Screens/Screen4";
import okta from "./lib/okta";
import get from "lodash/get";
import makeCancelable from "./lib/makeCancelable";
import withAuth from "./lib/withAuth";
import queries from "./graphQL/queries";
import Landing from "./pages/Landing";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage/>,
  },
  {
    path: "/xl/one",
    element: <Screen1/>,
  },
  {
    path: "/xl/two",
    element: <Screen2/>,
  },
  {
    path: "/xl/three",
    element: <Screen3/>,
  },
  {
    path: "/xl/four",
    element: <Screen4/>,
  },
]);

interface MyProps {

}

interface MyState {
  isLoggedIn: boolean | null
}

export default class App extends Component {
  state: MyState = {
    // isLoggedIn: null
    isLoggedIn: true
  }
  private queryCall: any;

  shouldComponentUpdate() {
    return true;
  }

  componentDidMount () {
    // this.checkAccessToken();
  }

  async checkAccessToken () {
    const accessToken = await okta.getAccessToken();
    if (accessToken) {
      this.verifyUser(accessToken);
      // this.setState({isLoggedIn: true});
    } else {
      this.setState({isLoggedIn: false});
    }
  }

  verifyUser (accessToken: String) {
    this.queryCall = makeCancelable(withAuth(
      queries.USER_DETAILS()
    ));
    this.queryCall.promise
      .then((response: any) => {
        const user = get(response, "data.user", null);
        if (user) {
          // const {setUser} = this.props;
          // setUser(user);
          this.setState({isLoggedIn: true});
        } else {
          this.invalidUser();
        }
      })
      .catch((e: any) => {
        console.error({e, message: "could not verify user"});
        this.invalidUser();
      });
  }

  invalidUser () {
    okta.deleteAccessToken()
      .then(() => {
        this.setState({isLoggedIn: false});
      });
  }

  render() {
    const {isLoggedIn} = this.state;

    return (
      <>
        {isLoggedIn === false &&
          <Landing/>}

        {isLoggedIn === true &&
          <RouterProvider router={router}/>}
      </>
    );
  }
}
