import React from 'react';
import './LineChartMetric/style.scss';
// import {LineChart, Line} from 'recharts';

const TextOnlyMetric = ({ title, total }) => {

  return (
    <div style={{paddingLeft: 32}}>
      {/* <LineChart width={350} height={144} data={data}>
        <Line 
          type="monotone" 
          dataKey="uv" 
          stroke="#FFFFFF"
          strokeWidth={5}
          strokeLinecap='round'
          dot={false}
        />
      </LineChart> */}

      <div className="metric-total">{total}</div>
      <div className="metric-title">{title}</div>
    </div>
  );
};

export default TextOnlyMetric;