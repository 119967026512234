import React, {Component} from "react";
import ScreenHeader, {MenuScreen} from "../_components/ScreenHeader";
import ScreenTitleRowTwo from "./ScreenTitleRow";
import ScreenGuide from "../_components/ScreenGuide";
import BarChart from "../_components/BarChart";
import TextOnlyMetric from "../_components/TextOnlyMetric";
import Timeline from "../_components/TimeLine";

export default class Screen2 extends Component {
  shouldComponentUpdate () {
    return true;
  }

  render () {
    const data2 = [
      {name: '01', uv: 23},
      {name: '02', uv: 1},
      {name: '03', uv: 45},
      {name: '04', uv: 10},
      {name: '05', uv: 550},
      {name: '07', uv: 490},
      {name: '08', uv: 500},
      {name: '09', uv: 510},
      {name: '10', uv: 810},
      {name: '11', uv: 910},
      {name: '12', uv: 510},
      {name: '13', uv: 0},
      {name: '14', uv: 0},
      {name: '15', uv: 0},
      {name: '16', uv: 0},
      {name: '17', uv: 0},
      {name: '18', uv: 87},
      {name: '19', uv: 166},
      {name: '20', uv: 201},
      {name: '21', uv: 333},
      {name: '22', uv: 653},
      {name: '23', uv: 234},
      {name: '24', uv: 121},
    ];

    const data3 = [
      {name: 'Page A', uv: 90},
      {name: 'Page B', uv: 125},
      {name: 'Page C', uv: 5},
      {name: 'Page D', uv: 20},
      {name: 'Page E', uv: 270},
      {name: 'Page F', uv: 250},
      {name: 'Page G', uv: 390},
      {name: 'Page H', uv: 400},
      {name: 'Page I', uv: 510},
      {name: 'Page j', uv: 610},
      {name: 'Page k', uv: 710},
      {name: 'Page l', uv: 410},
      {name: 'Page m', uv: 123},
      {name: 'Page n', uv: 222},
      {name: 'Page o', uv: 333},
      {name: 'Page p', uv: 765},
      {name: 'Page q', uv: 45},
      {name: 'Page r', uv: 87},
      {name: 'Page s', uv: 166},
      {name: 'Page t', uv: 501},
      {name: 'Page u', uv: 433},
      {name: 'Page v', uv: 253},
      {name: 'Page w', uv: 134},
      {name: 'Page x', uv: 21},
    ];

    const data4 = [
      {name: 'Page A', uv: 100},
      {name: 'Page B', uv: 265},
      {name: 'Page C', uv: 255},
      {name: 'Page D', uv: 420},
      {name: 'Page E', uv: 500},
      {name: 'Page F', uv: 550},
      {name: 'Page G', uv: 490},
      {name: 'Page H', uv: 500},
      {name: 'Page I', uv: 510},
      {name: 'Page j', uv: 810},
      {name: 'Page k', uv: 910},
      {name: 'Page l', uv: 510},
      {name: 'Page m', uv: 0},
      {name: 'Page n', uv: 0},
      {name: 'Page o', uv: 0},
      {name: 'Page p', uv: 0},
      {name: 'Page q', uv: 0},
      {name: 'Page r', uv: 87},
      {name: 'Page s', uv: 166},
      {name: 'Page t', uv: 201},
      {name: 'Page u', uv: 333},
      {name: 'Page v', uv: 653},
      {name: 'Page w', uv: 234},
      {name: 'Page x', uv: 121},
    ];

    return (
      <>
        {/* <ScreenGuide/> */}
        {/*<ScreenHeader screen={MenuScreen.Screen2}/>*/}
        <ScreenTitleRowTwo/>
        <main>
          <div className="title-one-col">
            <BarChart title="Total STARS today" total="1,823" data={data3}/>
            <BarChart title="Website STARS today" total="1,022" data={data4}/>
            <BarChart title="App STARS today" total="315K" data={data2}/>
          </div>
          <div className="two-col">
            <div style={{display: 'flex', minWidth: '100%', flexDirection: 'row', justifyContent: 'space-between'}}>
              <TextOnlyMetric title="Articles published today" total="166"/>
              <TextOnlyMetric title="Average published per hour" total="13.8"/>
            </div>
            <Timeline/>
          </div>
        </main>
      </>
    );
  }
}
