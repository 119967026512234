import React, {Component} from "react";
import {createBrowserRouter, Route, RouterProvider} from "react-router-dom";
import Callback from "./Callback";
import Login from "./Login";

const router = createBrowserRouter( [
  {
    path: "/implicit/callback",
    element: <Callback/>,
  },
  {
    path: "/",
    element: <Login/>,
  },
]);

export default class Landing extends Component {
  shouldComponentUpdate () {
    return true;
  }

  render () {
    console.log('Landing');

    return (
      <>
        <RouterProvider router={router}/>
      </>
    );
  }
}
