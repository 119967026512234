import React, {Component} from "react";
import styled from "styled-components";
import settings from "../../../Settings";
import platform from "../../../lib/platform";

export default class Login extends Component {
  constructor ( props ) {
    super( props );

    this.state = {
      isModalOpen: false
    };

    this.handleLoginWithOkta = this.handleLoginWithOkta.bind( this );
    this.handleDismissModal = this.handleDismissModal.bind( this );
  }

  shouldComponentUpdate () {
    return true;
  }

  handleDismissModal () {
    this.setState( {isModalOpen: false} );
  }

  handleLoginWithOkta () {
    if ( window.location.host === "localhost:3000" ) {
      window.location.replace( "https://pulse-login.telegraph.co.uk?app=pulse&env=dev" );
    } else if (
      window.location.host === "pulse-fe-staging.platforms-preprod-gcp.telegraph.co.uk" ||
      window.location.host === "pulse-staging.telegraph.co.uk"
    ) {
      window.location.replace( "https://pulse-login.telegraph.co.uk?app=pulse&env=staging" );
    } else if (
      window.location.host === "pulse-fe-preprod.platforms-preprod-gcp.telegraph.co.uk" ||
      window.location.host === "pulse-preprod.telegraph.co.uk"
    ) {
      window.location.replace( "https://pulse-login.telegraph.co.uk?app=pulse&env=preprod" );
    } else if ( window.location.host === "pulse-fe-prod.platforms-prod-gcp.telegraph.co.uk" ) {
      window.location.replace( "https://pulse-login.telegraph.co.uk?app=pulse&env=prod" );
    } else {
      alert( "Invalid hostname: " + window.location.hostname );
    }
  }

  render () {
    return (
      <div style={{color: 'white'}}>
        <Padding>
          <Brand>
            Telegraph Pulse
          </Brand>

          <Intro>
            Real time analytics on the go
          </Intro>

          <Content>
            The Pulse app gives you instant access to content performance insights and improved control over
            alerts and notifications.
          </Content>

          <Buttons>
            <button
              color="primary"
              onClick={this.handleLoginWithOkta}
            >
              Sign in with Okta
            </button>
          </Buttons>

          <Footer>
            Version: {settings.version}
          </Footer>
        </Padding>
      </div>
    );
  }
}

const Padding = styled.div`
  padding: 0 32px !important;
  max-width: 440px;
  margin: 0 auto;
`;

const Brand = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25vh;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 32px;
  align-items: center;
`;

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 16px;
`;

const Content = styled.div`
  display: flex;
  font-weight: 500;
  line-height: 1.5em;
  font-size: 14px;
  margin-bottom: 48px;
`;

const Buttons = styled.div`
  display: flex;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 40px;
  color: #bbb;
  font-size: 11px;
`;
