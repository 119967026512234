import React, {ReactNode} from 'react';
import PropTypes from 'prop-types';
import {Checkmark} from 'react-ionicons';

interface MyProps {
  title: String,
  subtitle?: String,
  icon: ReactNode,
  primary?: Boolean
  secondary?: Boolean
  active?: Boolean
  onClick?: Function
}

interface MyState {

}

export default class DropDownButton extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const {onClick} = this.props;

    if (onClick) {
      onClick();
    }
  }

  render() {
    const {title, subtitle, icon, primary, secondary, active, onClick} = this.props;

    const buttonClasses = `dropdown-option ${primary ? 'primary' : ''} ${secondary ? 'secondary' : ''} ${
      active ? 'active' : ''
    }`;

    return (
      <button className={buttonClasses} onClick={this.handleClick}>
        <div className="button-icon-wrapper">{icon}</div>

        <div className="button-content">
          <div className="button-title">{title}</div>
          {subtitle && <div className="button-subtitle">{subtitle}</div>}
        </div>

        {active && <Checkmark color={"#12ebf2"} style={{position: 'absolute', right: 24, top: 26}}/>}
      </button>
    );
  }
}
