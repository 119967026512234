import React from 'react';
import './style.scss';
import {BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Rectangle} from 'recharts';

const VerticalBarChartMetric = ( {title, total, gradient} ) => {
  const gradients = {
    purple: {
      id: 'colorPurple',
      colors: ['#8B00FF', '#4B0082'],
    },
    red: {
      id: 'colorRed',
      colors: ['#8B0000', '#FFa200'],
    },
    green: {
      id: 'colorGreen',
      colors: ['#00FF00', '#008000'],
    },
    blue: {
      id: 'colorBlue',
      colors: ['#0000FF', '#0080FF'],
    },
  };

  const selectedGradient = gradients[gradient] || gradients.purple;

  const data = [
    {name: '01', uv: 23},
    {name: '02', uv: 1},
    {name: '03', uv: 45},
    {name: '04', uv: 10},
    {name: '05', uv: 550},
    {name: '07', uv: 490},
    {name: '08', uv: 500},
    {name: '09', uv: 510},
    {name: '10', uv: 810},
    {name: '11', uv: 910},
    {name: '12', uv: 510},
    {name: '13', uv: 0},
    {name: '14', uv: 0},
    {name: '15', uv: 0},
    {name: '16', uv: 0},
    {name: '17', uv: 0},
    {name: '18', uv: 87},
    {name: '19', uv: 166},
    {name: '20', uv: 201},
    {name: '21', uv: 36},
    {name: '22', uv: 65},
    {name: '23', uv: 33},
    {name: '24', uv: 22},
  ];

  const CustomTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <line x1={-12} y1={0} x2={-5} y2={0} stroke="#ccc" />
        <text x={-24} y={0} dy={4} fill="#666" fontSize={14} textAnchor="end">
          {payload.value}
        </text>
      </g>
    );
  };

  return (
    <div>
      <div className="metric-total">{total}</div>
      <div className="metric-title">{title}</div>
      <BarChart 
        width={400} 
        height={800} 
        data={data} 
        layout="vertical" 
        style={{left: -18}}
      >
        <defs>
          <linearGradient
            id={selectedGradient.id}
            x1="100%"
            y1="100%"
            x2="0%"
            y2="0%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={selectedGradient.colors[0]}/>
            <stop offset=".5" stopColor={selectedGradient.colors[1]}/>
            <stop offset="1" stopColor={selectedGradient.colors[1]}/>
          </linearGradient>
        </defs>
        {/* <CartesianGrid strokeDasharray="3 3"/> */}
        <XAxis tick={false}/>
        <YAxis dataKey="name"  interval={0} tickCount={data.length} domain={[0, 'dataMax']}  dx={-20} axisLine={false} tick={<CustomTick />}  />
        {/* <Tooltip/>
        <Legend/> */}
        <Bar
          dataKey="uv"
          fill={`url(#${selectedGradient.id})`}
          shape={<Rectangle radius={[15, 15, 15, 15]} />}
          barSize={14} 
          dx={16}
          style={{position: 'relative'}}
        />
      </BarChart>
    </div>
  );
};

export default VerticalBarChartMetric;