import React from 'react';

const Timeline = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      width: 'calc(100% - 32px)',
      height: '50px',
      marginLeft: 32,
    },
    line: {
      position: 'absolute',
      top: '25px',
      left: '0',
      width: '100%',
      height: '1px',
      backgroundColor: 'white',
      opacity: 0.5,
    },
    divider: {
      position: 'absolute',
      top: '15px',
      width: '1px',
      height: '10px',
      backgroundColor: 'white',
      opacity: 0.5,
    },
    label: {
      color: 'white',
      position: 'absolute',
      top: '-10px',
      fontSize: '12px',
      fontWeight: 'bold',
      textAlign: 'center',
      width: '60px',
    },
    dataBubbleList: {
      position: 'absolute',
      top: '32px',
      left: '2px',
      width: '52px',
      height: 'fit-content',
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      marginLeft: '3px',
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'column',
      
    },
    dataBubble: {
      width: '100%',
      height: '10px',
      backgroundColor: 'cyan',
      borderRadius: 10,
      marginBottom: 5,
    },
  };

  const hours = Array.from({ length: 24 }, (_, i) => i);

  return (
    <div style={styles.container}>
      <div style={styles.line}></div>
      {hours.map((hour) => (
        <React.Fragment key={hour}>
          <div style={{ ...styles.divider, left: `${hour * 4.1666666667}%` }}></div>
          <div style={{ ...styles.dataBubbleList, left: `${hour * 4.1666666667}%` }}>
            {Array.from({ length: Math.floor(Math.random() * 7) }, (_, i) => (
              <div key={i} style={styles.dataBubble}></div>
            ))}
          </div>
          {hour % 2 === 0 && (
            <div style={{ ...styles.label, left: `${hour * 4.1666666667}%` }}>
              {hour.toString().padStart(2, '0')}:00
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default Timeline;