import React from 'react';
import './style.scss';

function ScreenTitleRowThree() {
  return (
    <div className="title-row">
     <div className="title-one-col">
        <div className="column-title">SUBSCRIPTION SPLIT</div>
        {/* <div className="column-subtitle">Realtime health is excellent <span>|</span> Latency 0% <span>|</span>  Quality 100%</div> */}
     </div>
     <div className="title-one-col">
        <div className="column-title">GLOBAL ACQUISITION ENGAGEMENT</div>
        {/* <div className="column-subtitle">Batch health is good <span>|</span> Latency 3% <span>|</span>  Quality 98%</div> */}
     </div>
   
    </div>
  );
}

export default ScreenTitleRowThree;