import React from 'react';
import './style.scss';
import { LineChart, Line, LinearGradient, Stop } from 'recharts';

const LineChartMetric = ({ title, total, data, gradient }) => {
  const gradients = {
    purple: {
      id: 'colorPurple',
      colors: ['#8B00FF', '#4B0082'],
    },
    red: {
      id: 'colorRed',
      colors: ['orange', 'red'],
    },
    green: {
      id: 'colorGreen',
      colors: ['#00FF00', '#008000'],
    },
  };

  const selectedGradient = gradients[gradient] || gradients.purple;

  return (
    <div style={{ paddingLeft: 32 }}>
      <LineChart width={350} height={144} data={data}>
        <defs>
          <linearGradient
            id={selectedGradient.id}
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop offset="0%" stopColor={selectedGradient.colors[0]} />
            <stop offset="100%" stopColor={selectedGradient.colors[1]} />
          </linearGradient>
        </defs>
        <Line
          type="monotone"
          dataKey="uv"
          stroke={`url(#${selectedGradient.id})`}
          strokeWidth={5}
          strokeLinecap="round"
          dot={false}
        />
      </LineChart>

      <div className="metric-total">{total}</div>
      <div className="metric-title">{title}</div>
    </div>
  );
};

export default LineChartMetric;