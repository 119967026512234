import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

const SubscriptionEvent = ( {title, location, timestamp} ) => {
  const [isVisible, setIsVisible] = useState( true );

  useEffect( () => {
    setTimeout( () => {
      setIsVisible( false );
    }, 5000 );

    // const interval = setInterval(() => {
    //   setIsVisible(true);
    //   setTimeout(() => {
    //     setIsVisible(false);
    //   }, 5000);
    // }, 20000);
    // return () => clearInterval(interval);
  }, [] );

  return (
    <div
      style={{
        position: 'relative',
        // bottom: '64px',
        // right: '64px',
        opacity: isVisible ? 1 : 0,
        transform: `scale(${isVisible ? 1 : 0.85})`,
        transition: 'opacity 0.4s ease-in-out, transform 0.4s ease-in-out',
        color: 'white',
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        backdropFilter: 'blur(20px) saturate(150%)',
        padding: '16px 32px',
        borderRadius: '8px',
        boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 1)',
        width: 488,
        marginBottom: 8,
      }}
    >
      <h3>{title}</h3>
      <p>{location}</p>
      <p>{timestamp}</p>
    </div>
  );
};

SubscriptionEvent.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
};

export default SubscriptionEvent;