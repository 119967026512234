import React from 'react';
import './CountryCard.scss'

interface CountryCardProps {
  countryName: string;
  subscriptions: number;
  stars: number;
  visitors: number;
  pageViews: number;
}

const CountryCard: React.FC<CountryCardProps> = ({
  countryName,
  subscriptions,
  stars,
  visitors,
  pageViews,
}) => {
  return (
    <div className="countryCard--wrapper">
      <div className="countryName">{countryName}</div>
      <div className="card-total">{subscriptions} <span>Subscriptions</span></div>
      <div className="card-total">{stars} <span>STARS</span></div>
      <div className="card-total">{visitors} <span>Visitors</span></div>
      <div className="card-total">{pageViews} <span>Page Views</span></div>
    </div>
  );
};

export default CountryCard;