import React from 'react';
import './style.scss';
import {ChevronDown, DocumentOutline, EarthOutline, EyeOffOutline, ImagesOutline, ShareSocial} from 'react-ionicons'
import DropdownButton from './DropdownButton';
import {Link} from "react-router-dom";
import {MenuScreen} from "../index";

interface MyProps {
  screen?: MenuScreen
}

interface MyState {
  isOpen: boolean
}

export default class Dropdown extends React.Component<MyProps, MyState> {
  state = {
    isOpen: false
  }

  constructor(props: MyProps) {
    super(props);

    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  toggleDropdown() {
    this.setState({isOpen: !this.state.isOpen});
  }

  render() {
    const {isOpen} = this.state;
    const {screen} = this.props;

    return (
      <div className="dropdown">
        <button className="dropdown-button" onClick={this.toggleDropdown}>
          {screen === MenuScreen.Home && 'Home'}
          {screen === MenuScreen.Screen1 && 'Screen 1'}
          {screen === MenuScreen.Screen2 && 'Screen 2'}
          {screen === MenuScreen.Screen3 && 'Screen 3'}
          {screen === MenuScreen.Screen4 && 'Screen 4'}

          <ChevronDown
            color={'#fff'}
            height="18px"
            width="18px"
            style={{marginLeft: '14px'}}
          />
        </button>
        {isOpen && (
          <>
            <div className="dropdown-overlay" onClick={this.toggleDropdown}/>
            <div className="dropdown-menu">
              <div className="dropdown-title">XL Screens</div>
              <Link to={'/'}>
                <DropdownButton
                  title="Home"
                  subtitle="Home Page"
                  icon={<DocumentOutline/>}
                  primary
                  active={screen === MenuScreen.Home}
                />
              </Link>
              <Link to={'/xl/one'}>
                <DropdownButton
                  title="Screen 1"
                  subtitle="Editorial daily morning brief data"
                  icon={<DocumentOutline/>}
                  primary
                  active={screen === MenuScreen.Screen1}
                />
              </Link>
              <Link to={'/xl/two'}>
                <DropdownButton
                  title="Screen 2"
                  subtitle="Published articles and referral sources"
                  icon={<ShareSocial/>}
                  secondary
                  active={screen === MenuScreen.Screen2}
                />
              </Link>
              <Link to={'/xl/three'}>
                <DropdownButton
                  title="Screen 3"
                  subtitle="Global subscription performance"
                  icon={<EarthOutline/>}
                  active={screen === MenuScreen.Screen3}
                />
              </Link>
              <Link to={'/xl/four'}>
                <DropdownButton
                  title="Screen 4"
                  subtitle="Global subscription performance"
                  icon={<EarthOutline/>}
                  active={screen === MenuScreen.Screen4}
                />
              </Link>

              <div className="dropdown-title">Optional tools</div>

              <DropdownButton
                title="Holding screen"
                subtitle="Displays XL Pulse 2.0 Brand"
                icon={"XL"}
                onClick={this.toggleDropdown}
              />

              <DropdownButton
                title="Image URL"
                subtitle="Add an image URL to show in full screen"
                icon={<ImagesOutline/>}
                onClick={this.toggleDropdown}
              />

              <DropdownButton
                title="Blank screen"
                subtitle="Simulates projector shutdown"
                icon={<EyeOffOutline/>}
                onClick={this.toggleDropdown}
              />

            </div>
          </>
        )}
      </div>
    )
  }
}

