import environments from './environments';
import settings from '../Settings';

let config = {};
if ( settings.environment === environments.live ) {
	console.log( 'Live Server' );
	config = require( './production/graphql' );
} else {
	console.log( 'Dev Server' );
	config = require( './development/graphql' );
}

export default config;
