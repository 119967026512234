import React, {Component} from "react";
import ScreenHeader, {MenuScreen} from "../_components/ScreenHeader";

export default class Screen4 extends Component {
  shouldComponentUpdate () {
    return true;
  }

  render () {
    return (
      <>
        <ScreenHeader screen={MenuScreen.Screen4}/>
        <main></main>
        <footer></footer>
      </>
    );
  }
}
