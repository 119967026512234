import Cookies from 'universal-cookie';
const cookies = new Cookies(null, { path: '/' });

class OktaStorage {
	constructor () {
		this.key = "okta-access-token";
		this.accessToken = null;
		this.getAccessToken();
	}

	async setAccessToken (value) {
		this.accessToken = value;
		cookies.set( this.key, value );
	}

	getAccessToken () {
		if (this.accessToken) {
			return this.accessToken;
		}

		const ret = cookies.get(this.key);
		this.accessToken = ret;
		return ret;
	}

	syncGetAccessToken () {
		return this.accessToken;
	}

	async deleteAccessToken () {
		this.accessToken = null;
		cookies.remove(this.key);
	}
}

const okta = new OktaStorage();

export default okta;
