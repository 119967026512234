import React from 'react';
import './ArticleRow.scss';

const ArticleRow = ({ image, title, author, published, total, pinned }) => {
  return (
    <div className={`article-row ${pinned ? 'pinned' : ''}`}>
        <img className="thumbnail" src={image} alt={""} />

        <div className="article-info">
            <div className="headline">{title}</div>
            <div className="author">By {author} on {published}</div>
            <div className="stars-wrapper">
              <div className="engagement" />
              <div className="acquisition" />
              <div className="retention" />
            </div>
        </div>
        
        <div className="tag">News</div>
        
        <div className="total">{total}</div>
    </div>
  );
};

export default ArticleRow;