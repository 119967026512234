import React, {Component} from "react";
import {Link} from "react-router-dom";
import ScreenHeader, {MenuScreen} from "../Screens/_components/ScreenHeader";

export default class HomePage extends Component {
  shouldComponentUpdate () {
    return true;
  }

  render () {
    return (
      <>
        <ScreenHeader screen={MenuScreen.Home}/>
        <div>
          <Link to={`xl/one`}>Screen 1</Link>
        </div>
        <div>
          <Link to={`xl/two`}>Screen 2</Link>
        </div>
        <footer></footer>
      </>
    );
  }
}
