import React from 'react';

const GradientOverlay = () => {
  const styles = {
    position: 'fixed',
    top: 900,
    left: 1020,
    zIndex: 999,
    width: 900,
    height: 180,
    PointerEvent: 'none',
    background: 'linear-gradient(to bottom, transparent 0%,black 80%, black 100%)',

  };

  return <div style={styles}></div>;
};

export default GradientOverlay;