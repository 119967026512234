import React, {Component} from 'react';
import './style.scss';
import Dropdown from './Dropdown';

interface MyProps {
  screen?: MenuScreen
}

interface MyState {
  isVisible: boolean
}

export enum MenuScreen {
  Home,
  Screen1,
  Screen2,
  Screen3,
  Screen4,
}

export default class ScreenHeader extends React.Component<MyProps, MyState> {
  private timeoutId?: NodeJS.Timeout;

  constructor(props: MyProps) {
    super(props);

    this.state = {
      isVisible: true,
    };
  }

  componentDidMount() {
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount() {
    document.removeEventListener('mousemove', this.handleMouseMove);
    clearTimeout(this.timeoutId);
  }

  handleMouseMove = () => {
    document.getElementsByTagName('body')[0].style.cursor = "default";
    this.setState({isVisible: true});
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      document.getElementsByTagName('body')[0].style.cursor = "none";
      this.setState({isVisible: false});
    }, 5000);
  };

  render() {
    const {isVisible} = this.state;
    const {screen} = this.props;

    return (
      <div className={`ScreenHeader ${isVisible ? 'visible' : 'hidden'}`}>
        <h1>XL <span>PULSE</span></h1>

        <Dropdown screen={screen}/>

        <div className="version">
          XL Version 2.0
        </div>

      </div>
    );
  }
}

