import React from 'react';
import ScreenHeader, {MenuScreen} from '../_components/ScreenHeader';
import ScreenGuide from '../_components/ScreenGuide';
import './style.scss';
import ScreenTitleRowThree from './ScreenTitleRowThree';
import SubscriptionEvent from '../_components/SubscriptionEvent';
import GlobeView from "./GlobeView";
import VerticalBarChartMetric from '../_components/VerticalBarChart';
import CountryCard from '../_components/CountryCard';

const Screen3 = () => {
  return (
    <>
      <ScreenTitleRowThree/>
      {/* <ScreenGuide /> */}
      {/* <ScreenHeader screen={MenuScreen.Screen3} /> */}
      <main style={{display: 'flex', justifyContent: 'end', alignItems: 'end'}}>
        <div className="fixed-one-col">
          <VerticalBarChartMetric title="Total subscrptions today" total="1,823" gradient="red"/>
        </div>

        <div className="fixed-col-two">
        <CountryCard
          countryName="United Kingdom"
          subscriptions={1823}
          stars={1876}
          visitors={126656}
          pageViews={199876}
        />

        <CountryCard
          countryName="United States of America"
          subscriptions={1823}
          stars={1876}
          visitors={126656}
          pageViews={199876}
        />

        <CountryCard
          countryName="Australia"
          subscriptions={1823}
          stars={1876}
          visitors={126656}
          pageViews={199876}
        />

        </div>
        <GlobeView/>
      </main>
    </>
  );
};

export default Screen3;