const metrics = {
	SUB: "SUB",
	REG: "REG",
	PAGEVIEW: "PAGEVIEW",
	VISITORS: "VISITOR",
	COMMENTS: "COMMENT",
	DWELL_TIME: "DWELL_TIME",
	PAGE_COMPLETION: "PAGE_COMPLETION",
	STARS: "STARS",
	ACQUISITION: "ACQUISITION",
	ENGAGEMENT: "ENGAGEMENT",
	RETENTION: "RETENTION",
};

export default metrics;
