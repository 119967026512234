import React from 'react';
import './style.scss';

function ScreenTitleRowTwo() {
  return (
    <div className="title-row">
     <div className="title-one-col">
        <div className="column-title">STARS BREAKDOWN</div>
        <div className="column-subtitle warning">Data unavailable for last 12 minutes</div>
     </div>
     <div className="title-one-col">
        <div className="column-title">PUBLISHED ARTICLES BREAKDOWN</div>
        <div className="column-subtitle success">Data available and up to date</div>
     </div>
   
    </div>
  );
}

export default ScreenTitleRowTwo;