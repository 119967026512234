import okta from "./okta";
import get from "lodash/get";

const withAuth = (promise) => {
	return new Promise((resolve, reject) => {
		promise.then(
			val => resolve(val),
			error => {
				if (error.networkError) {
					if (get(error, "networkError.statusCode") === 401 || get(error, "networkError.result.error") === "Access token is not validated") {
						okta.deleteAccessToken();
						window.location.reload();
					}
				}
				return reject(error);
			}
		);
	});
};

export default withAuth;
