import React from 'react';
import './style.scss';
import { BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Rectangle } from 'recharts';

const BarChartMetric = ({ title, total, data, gradient }) => {
  const gradients = {
    purple: {
      id: 'colorPurple',
      colors: ['#a888ff', '#f088ff'],
    },
    red: {
      id: 'colorRed',
      colors: ['#FF0000', '#8B0000'],
    },
    green: {
      id: 'colorGreen',
      colors: ['#00FF00', '#008000'],
    },
    blue: {
      id: 'colorBlue',
      colors: [ '#1000ff','#00FFF3'],
    },
  };

  const selectedGradient = gradients[gradient] || gradients.purple;

  return (
    <div style={{ paddingLeft: 32 }}>
      <BarChart width={350} height={144} data={data}>
        <defs>
          <linearGradient
            id={selectedGradient.id}
            x1="0"
            y1="0"
            x2="0"
            y2="100%"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor={selectedGradient.colors[0]} />
            <stop offset=".5" stopColor={selectedGradient.colors[1]} />
            <stop offset="1" stopColor={selectedGradient.colors[0]} />
          </linearGradient>
        </defs>
        {/* <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend /> */}
 <Bar
          dataKey="uv"
          fill={`url(#${selectedGradient.id})`}
          shape={<Rectangle radius={[5, 5, 5, 5]} />}
        />      </BarChart>

      <div className="metric-total">{total}</div>
      <div className="metric-title">{title}</div>
    </div>
  );
};

export default BarChartMetric;